<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="4">
          <b-form-group
            label-for="cepInput"
            label="CEP"
          >
            <cep-input
              :id="'cepInput'"
              ref="cepInput"
              :value="valueIn.cep"
              @endereco="buscarEndereco($event)"
              @input="value.cep = $event"
            />
          </b-form-group>

        </b-col>
        <b-col cols="6">
          <b-form-group
            label-for="logradouroInput"
            label="Endereço"
          >

            <b-form-input
              id="logradouroInput"
              v-model="valueIn.logradouro"
              @input="enviarPai"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label-for="numeroInput"
            label="Número"
          >

            <b-form-input
              id="numeroInput"
              v-model="valueIn.numero"
              @input="enviarPai"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <b-form-group
            label-for="complementoInput"
            label="Complemento"
          >
            <b-form-input
              id="complementoInput"
              v-model="valueIn.complemento"
              @input="enviarPai"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label-for="bairroInput"
            label="Bairro"
          >

            <b-form-input
              id="bairroInput"
              v-model="valueIn.bairro"
              @input="enviarPai"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group
            label-for="estadoInput"
            label="UF"
          >
            <estado-select
              id="estadoInput"
              v-model="valueIn.uf"
              :title="'UF'"
              @input="enviarPai"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label-for="cidadeInput"
            label="Cidade"
          >
            <municipio-select
              id="cidadeInput"
              v-model="valueIn.municipio"
              title="Cidade"
              :uf="valueIn.uf ? valueIn.uf.sigla : ''"
              @input="enviarPai"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label-for="loginInput"
            label="E-mail"
          >
            <validation-provider
              #default="{ errors }"
              name="Email de contato"
              rules="email"
            >
              <b-form-input
                id="loginInput"
                v-model="value.email"
                name="login-email"
                :state="errors.length > 0 ? false:null"
                placeholder="pessoa@empresa.com.br"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <telefone-celular
            v-model="value.telefoneContato"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-for="emailAdicionalInput"
            label="E-mails adicionais"
          >
            <b-form-tags
              v-if="value.emailAdicional"
              id="emailAdicionalInput"
              v-model="value.emailAdicional"
              placeholder=""
              separator=";"
              invalid-tag-text="E-mail inválido"
              duplicate-tag-text="Possui e-mails repitidos"
              :tag-validator="validarEmail"
              :add-button-text="'Adicionar'"
              remove-on-delete
              @input="enviarPai"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="mostraFax"
          cols="3"
        >
          <b-form-group
            label="Fax"
            label-for="faxInput"
          >
            <b-input-group>
              <masked-input
                id="faxInput"
                ref="fax"
                v-model="value.telefoneContato.fax"
                :mask="['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                class="form-control"
                placeholder=""
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import {
  required,
} from '@validations'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'Endereco',
  components: {
    ValidationProvider,
    EstadoSelect: () => import('@pilar/components/estado-select/EstadoSelect.vue'),
    MunicipioSelect: () => import('@pilar/components/municipio-select/MunicipioSelect.vue'),
    TelefoneCelular: () => import('@pilar/components/telefone-celular/TelefoneCelular.vue'),
    CepInput: () => import('@pilar/components/cep-input/CepInput.vue'),
  },
  props: {
    value: {
      type: Object,
      default: () => { },
    },
    telefoneObrigatorio: {
      type: Boolean,
      default: false,
    },
    emailObrigatorio: {
      type: Boolean,
      default: false,
    },
    mostraFax: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      valueIn: {
        bairro: '',
        cep: '',
        complemento: '',
        email: '',
        emailAdicional: [],
        logradouro: '',
        numero: '',
        telefoneContato: {
          telefone: '',
          celular: '',
          fax: '',
        },
        tipoLogradouro: '',
        municipio: {},
        uf: {},
      },
      erroCep: false,
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueIn = value
      }
    },
  },
  created() {
    this.valueIn = this.value
  },
  methods: {
    async buscarEndereco(endereco) {
      this.$set(this.valueIn, 'uf', endereco.uf)
      this.$set(this.valueIn, 'municipio', endereco.municipio)
      this.$set(this.valueIn, 'logradouro', endereco.logradouro)
      this.$set(this.valueIn, 'bairro', endereco.bairro)
      this.$set(this.valueIn, 'complemento', endereco.complemento)
    },
    enviarPai() {
      this.$emit('input', this.valueIn)
    },
    validarCep() {
      return true // this.$refs.cepInput.validarCep()
    },
    validarEmail(tag) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(tag)
    },
  },
}
</script>
